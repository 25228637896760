/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
/* body {
    background-color: #e9ebee !important;
    font-family: "Poppins", sans-serif !important;
} */

/* html, body, #root {
    width: 100% !important;
    height: 100% !important;
} */

html, body, #root {
    width: 100% !important;
    height: auto !important;
    /* background-color: rgba(0, 0, 0, 0) !important; Fully transparent */
    background-color: #fFF !important;
}

a {
    text-decoration: none;
    color: inherit
}


.personal-logo {
    width: 7rem;
    max-width: 7rem;
    height: 7rem;
    max-height: 7rem;
    margin-top: -5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.personal-logo-speck {
    width: 1.4rem;
    max-width: 1.4rem;
    height: 1.4rem;
    max-height: 1.4rem;
    margin-top: -1.4rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.personal-logo-xxs {
    width: 2.5rem;
    max-width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    margin-top: -2.5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.personal-logo-xs {
    width: 3rem;
    max-width: 3rem;
    height: 3rem;
    max-height: 3rem;
    margin-top: -3rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.personal-logo-sm {
    width: 5rem;
    max-width: 5rem;
    height: 5rem;
    max-height: 5rem;
    margin-top: -5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.personal-logo-lg {
    width: 10rem;
    max-width: 10rem;
    height: 10rem;
    max-height: 10rem;
    margin-top: -6rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.personal-logo-xl {
    width: 13rem;
    max-width: 13rem;
    height: 13rem;
    max-height: 13rem;
    margin-top: -5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.personal-logo-xxl {
    width: 18rem;
    max-width: 18rem;
    height: 18rem;
    max-height: 18rem;
    margin-top: -5rem;
    border: 3px solid #fff;
    border-radius: 10%;
    background-color: #fff;
    background-size: cover;
    background-position: center center
}

.personal-logo-px-1 {
    width: 40px;
    max-width: 40px;
    height: 40px;
    max-height: 40px;
    border-radius: 10%;
    background-size: cover;
    background-position: center center
}

.personal-logo-px-2 {
    width: 30px;
    max-width: 30px;
    height: 30px;
    max-height: 30px;
    border-radius: 10%;
    background-size: cover;
    background-position: center center
}

.personal-logo-flat-top {
    margin-top: 0 !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
    box-shadow: 0 .2rem 0.5rem rgba(0, 0, 0, .20) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.shadow-switch {
    box-shadow: 0 0 .4rem rgba(0, 0, 0, 16%) !important
}

.w-25 {
    width: 25% !important
}

.c-pointer {
    cursor: pointer;
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 10px !important;
    color: #292D32 !important;
    padding: 10px 15px !important;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .68) !important;
    border-radius: 7px !important;
    background-color: #FFFFFF !important;
}

.swiper-button-disabled, .swiper-button-disabled {
    opacity: 0!important;
}

.swal2-container {
    z-index: 1102 !important;
}

.swiper-container {
    z-index: 0 !important;
}

.react-joyride__spotlight{
    background-color: rgba(255, 255, 255, .15) !important;
    border: solid 2px white;
}

.text-justify {
    text-align: justify !important;
}

.receipt-body-1 {
    position: relative;
}

.receipt-body-1:before {
    content: "";
    display: block;
    background: #EBF2F0;
    position: absolute;
    bottom: -18px;
    left: -18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.receipt-body-1:after{
    content: "";
    display: block;
    background: #EBF2F0;
    position: absolute;
    bottom: -18px;
    right: -18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.receipt-body-2 {
    position: relative;
}

.receipt-body-2:before {
    content: "";
    display: block;
    background: #fff;
    position: absolute;
    bottom: -18px;
    left: -18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.receipt-body-2:after{
    content: "";
    display: block;
    background: #fff;
    position: absolute;
    bottom: -18px;
    right: -18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 400px;
}

.paypal {
    z-index: 1;
}

.cover-paypal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 0.5px solid rgb(189, 189, 189);
}

.separator::before {
    margin-right: 0.75em;
}

.separator::after {
    margin-left: 0.75em;
}

@media screen and (max-width: 600px) {
    .otp-inputs {
        font-size: 32px ;
        margin: 5px ;
        min-width: 40px !important;
        height: 63px !important;
        /* border: 1px solid #0070EF; */
        border-radius: 10px;
        caret-color: transparent;
        box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15);
    }
}

.otp-inputs {
    font-size: 32px;
    margin: 5px;
    min-width: 73px;
    height: 90px;
    /* border: 1px solid #0070EF; */
    border-radius: 10px;
    caret-color: transparent;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15);
    
}

.otp-inputs.bgbiz:focus {
    outline: 1px solid #11783C;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15), inset 0px 0px 0px 15px #ffffff, inset 0px -20px 0px 0px #11783C !important;
}

.otp-inputs.bgpsl:focus {
    outline: 1px solid #0070EF;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15), inset 0px 0px 0px 15px #ffffff, inset 0px -20px 0px 0px #0070EF !important;
}

.bgbiz {
    /* border: 1px solid #11783C */
    border: 1px solid transparent
}

.bgpsl {
    /* border: 1px solid #0070EF */
    border: 1px solid transparent
}

.otp-sm-inputs {
    font-size: 24px;
    margin: 5px;
    min-width: 48px;
    height: 60px;
    /* border: 1px solid #0070EF; */
    border: 1px solid transparent;
    border-radius: 10px;
    caret-color: transparent;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15);
}

.otp-md-inputs {
    font-size: 24px;
    font-weight: 600;
    margin: 5px;
    min-width: 53px;
    height: 64px;
    /* border: 1px solid #0070EF; */
    border: 1px solid #B5BBC1;
    /* border: 1px solid transparent; */
    border-radius: 10px;
    caret-color: transparent;
    box-shadow: 0 0rem 0rem rgba(0, 0, 0, .15);
}

.otp-flexed-around-sm-inputs {
    font-size: 24px;
    min-width: 48px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.otp-sm-inputs.bg-sm-biz:focus {
    outline: 1px solid #11783C;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15), inset 0px 0px 0px 15px #ffffff, inset 0px -20px 0px 0px #11783C !important;
}

.otp-sm-inputs.bg-sm-psl:focus {
    /* outline: 1px solid #0070EF; */
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .15), inset 0px 0px 0px 15px #ffffff, inset 0px -20px 0px 0px #0070EF !important;
}

.bg-sm-biz {
    border: 1px solid transparent
}

.bg-sm-psl {
    border: 1px solid transparent
}

.bg-otp-mismatched {
    border: 1px solid #f44336
}

.chaport-container{
    bottom: 3em !important;
    right: -1.2em !important;
}

.chaport-container .chaport-launcher .chaport-launcher-button {
    width: 3.28571em !important;
    height: 3.28571em !important;
}

.chaport-container .chaport-launcher .chaport-launcher-button .chaport-launcher-chat-icon {
    width: 1.28571em !important;
    height: 1.4em !important;
}

.chaport-container .chaport-launcher .chaport-launcher-button .chaport-launcher-close-icon::before, .chaport-container .chaport-launcher .chaport-launcher-button .chaport-launcher-close-icon::after {
    right: 1.55em !important;
    top: 1em !important;
}

.chaport-container .chaport-launcher .chaport-launcher-button .chaport-launcher-close-icon{
    width: 0 !important;
    height: 0 !important;
}